import React, { Component } from "react";
import logomini from "../../src/assets/img/logo-mini.svg";
import "./Footersection.css"
const Footersection = () => {
  return (
    <div className="w-100 overflow-hidden px-3 px-lg-5 px-xxl-5 px-xl-5 px-md-5 footer-section">
      <div className="row row-footer gy-3 w-100 justify-content-between py-3 overflow-hidden">
        <div className="col-auto ">
          <img src={logomini} alt="" className="footer-logo" />
        </div>
        <div className="col-auto align-items-center d-flex   copyright order-sm-last order-first order-xxl-last order-xl-last order-md-last">
          © 2021 MY PET & I. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footersection;
